export default [
  {
    key: 'image',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'user',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'companyId',
    label: 'field.company',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'company',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    rules: 'required|max:50',
    type: 'text',
  },
  {
    key: 'positionId',
    label: 'field.position',
    rules: '',
    type: 'nAsynSingleSelection',
    repository: 'position',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    rules: 'max:50',
    type: 'tel',
  },
  {
    key: 'roles',
    label: 'field.role',
    rules: '',
    type: 'nAsynMultiSelection',
    repository: 'role',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required|max:100',
    type: 'text',
    cols: 12,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6|max:50',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
